import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { AppContext } from '../store/context'
import { arrayContainsArray } from '../utils/array-utils'
import ROUTES, { DASHBOARD_ROUTES } from '../utils/ROUTES'
import SvgIcon from './SvgIcon'

interface Props {
  detailPage?: {
    path: string
    name: string
  }
}

const BreadCrumbs: React.FC<Props> = ({ detailPage }: Props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const {
    state: {
      impersonnation: { isImpersonnation },
    },
    roleNames,
  } = useContext(AppContext)

  const excludedPath = [
    '/',
    ROUTES.Dashboard,
    ROUTES.Dashboard2,
    ROUTES.UserImpersonation,
  ]
  const homeBreadcrumbItem = {
    path: isImpersonnation ? ROUTES.Dashboard : '/',
    name: t('breadCrumbs.items.Home'),
  }

  let crumbsWithoutHome = (Object.keys(ROUTES) as (keyof typeof ROUTES)[])
    .filter(
      (routeKey) =>
        !excludedPath.includes(ROUTES[routeKey]) &&
        DASHBOARD_ROUTES.includes(ROUTES[routeKey]) &&
        arrayContainsArray(
          ROUTES[routeKey].replace(/\/:id(.*)/, '').split('/'),
          location.pathname.split('/')
        )
    )
    .map((routeKey) => ({
      path:
        ROUTES[routeKey] === ROUTES.Administration
          ? ROUTES.UsersManagement
          : ROUTES[routeKey],
      name: t(`breadCrumbs.items.${routeKey}`),
    }))

  // TODO : changer le périmètre expand en un système de tabulations
  if (
    location.pathname.includes(ROUTES.ExpandPerimeter) ||
    location.pathname.includes(ROUTES.ExpandPerimeterMultiple)
  ) {
    crumbsWithoutHome = [
      {
        path: ROUTES.ProfileOrganization,
        name: t(`breadCrumbs.items.ProfileOrganization`),
      },
      {
        path: ROUTES.ExpandPerimeter,
        name: t(`breadCrumbs.items.ExpandPerimeter`),
      },
    ]
  }

  const allCrumbsItems = [homeBreadcrumbItem, ...crumbsWithoutHome]

  if (location.pathname.includes('/my-demands/details')) {
    if (detailPage?.name) {
      const lastCrumb = allCrumbsItems[allCrumbsItems.length - 1]
      lastCrumb.name += detailPage.name
      lastCrumb.path = lastCrumb.path
        .split('/')
        .map((part) => {
          return part === ':id' ? detailPage.path : part
        })
        .join('/')
    }
  }

  if (
    location.pathname.includes('/modify-organization/') &&
    roleNames.includes('ROLE_INT_SUPPORT_N1')
  ) {
    allCrumbsItems[allCrumbsItems.length - 1].name = t(
      `modifyOrganization.label.detailTitle`
    )
  }

  if (allCrumbsItems.length <= 1) return null

  return (
    <ul className="breadCrumbs d-flex list-inline pt-4 pl-2">
      {allCrumbsItems.map(({ name, path }, key) =>
        key + 1 === allCrumbsItems.length ? (
          <li
            key={`${name}-${path}`}
            className="breadCrumbs__item breadCrumbs__item--selected"
          >
            {name}
          </li>
        ) : (
          <li
            key={`${name}-${path}`}
            className="breadCrumbs__item d-flex align-items-center"
          >
            <Link to={path} className="breadCrumbs__link">
              {name}
            </Link>
            <SvgIcon
              name="arrowRight"
              className="breadcrumb__arrow mx-3 mt-1"
            />
          </li>
        )
      )}
    </ul>
  )
}

BreadCrumbs.defaultProps = {
  detailPage: undefined,
}

export default BreadCrumbs
