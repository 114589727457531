import React, { useContext, useEffect, useState } from 'react'
import { Route, useHistory, Switch, useLocation } from 'react-router-dom'
import './utils/i18n'
import toast, { Toaster, ToastBar } from 'react-hot-toast'
import Cookies from 'universal-cookie'
import ROUTES, {
  DASHBOARD_ROUTES,
  PATHS_WITHOUT_PERIMETER_FILTER,
} from './utils/ROUTES'
import WasteRegister from './pages/WasteRegister'
import Footer from './components/Footer'
import HeaderAuthenticated from './components/HeaderAuthenticated'
import { AppContext } from './store/context'
import PERIMETER_MODE from './utils/PERIMETER_MODE'
import CustomRoute from './components/CustomRoute'
import ROLES from './utils/ROLES'
import AuthenticatedScreenInnerContainer from './layouts/AuthenticatedScreenInnerContainer'
import ToastMessagesEntity from './utils/ToastMessageEntity'
import { getToastMessages } from './services/messages-services'
import SvgIcon from './components/SvgIcon'
import BreadCrumbs from './components/BreadCrumbs'
import getCheckMaintenance from './services/maintenance-service'
import { canManageDocuement } from './services/roles-services'
import { searchInstitutionByTextAndOrganizationsIds } from './services/institution-services'
import { ActionTypes } from './store/actions'
import MAINTENANCE_TYPE from './utils/maintenance-utils'
import SessionAuthenticated from './components/SessionAuthenticated'

const DashboardPage = React.lazy(() => import('./pages/DashboardPage'))
const Dashboard2 = React.lazy(() => import('./pages/Dashboard/Dashboard2'))
const Billing = React.lazy(() => import('./pages/Billing'))
const UserPerimeter = React.lazy(() => import('./pages/UserPerimeter'))
const AttachmentInstitution = React.lazy(
  () => import('./pages/AttachmentInstitution')
)
const UserProfile = React.lazy(() => import('./pages/UserProfile'))
const CreateOrganizationPage = React.lazy(
  () => import('./pages/CreateOrganizationPage')
)
const BillingDetail = React.lazy(() => import('./pages/BillingDetail'))
const Profile = React.lazy(() => import('./pages/Profile'))
const ActivitySynthesis = React.lazy(
  () => import('./pages/ActivitySynthesis/StandartReports')
)
const ActivityAdvancedReports = React.lazy(
  () => import('./pages/ActivitySynthesis/AdvancedReports')
)

const MonoAdvancedReport = React.lazy(
  () => import('./pages/ActivitySynthesis/AdvancedReport')
)
const ActivityAnalyseEvolution = React.lazy(
  () => import('./pages/ActivityAnalyseEvolution')
)
const BillingSynthesis = React.lazy(() => import('./pages/BillingSynthesis'))
const BillingAnalyseEvolution = React.lazy(
  () => import('./pages/BillingAnalyseEvolution')
)
const MyDocuments = React.lazy(() => import('./pages/MyDocuments'))
const AttestationValorisation = React.lazy(
  () => import('./pages/AttestationValorisation')
)
const HandleDocument = React.lazy(() => import('./pages/HandleDocument'))
const UsersManagement = React.lazy(() => import('./pages/UsersManagement'))
const UserImpersonation = React.lazy(() => import('./pages/UserImpersonation'))
const ModifyOrganization = React.lazy(
  () => import('./pages/ModifyOrganization')
)
const ModifyUserPerimeter = React.lazy(
  () => import('./pages/ModifyUserPerimeter')
)
const MessagesManagement = React.lazy(
  () => import('./pages/MessagesManagement')
)
const MyDemands = React.lazy(() => import('./pages/MyDemands'))
const CreateCommunication = React.lazy(
  () => import('./pages/SuperAdminPage/Communications/CreateCommunication')
)
const ListeCommunication = React.lazy(
  () => import('./pages/SuperAdminPage/Communications/ListeCommunication')
)
const CreateDemand = React.lazy(() => import('./pages/MyDemandsCreation'))
const DemandDetail = React.lazy(() => import('./pages/MyDemands/Details'))
const CreateSideMessage = React.lazy(() => import('./pages/CreateSideMessage'))
const ExpandPerimeter = React.lazy(() => import('./pages/ExpandPerimeter'))
const SuperAdmin = React.lazy(
  () => import('./pages/SuperAdminPage/SuperAdminPage')
)
const KPIs = React.lazy(() => import('./pages/SuperAdminPage/KPIs'))
const ListeNotification = React.lazy(
  () => import('./pages/NotificationsPage/ListeNotification')
)

function AuthenticatedRoutes() {
  const cookies = new Cookies()
  const history = useHistory()
  const {
    state: {
      user,
      institutionPerimeter,
      impersonnation: { accessDemands, emailImpersonated },
      ui,
    },
    roleNames,
    dispatch,
  } = useContext(AppContext)
  const location = useLocation()
  const ACCESS_DEMANDS = Boolean(
    parseInt(process?.env?.REACT_APP_ACCESS_DEMANDS || '1', 10)
  )
  const ACCESS_NEW_DASHBOARD = Boolean(
    parseInt(process?.env?.REACT_APP_ACCESS_NEW_DASHBOARD || '1', 10)
  )

  const hasAccessToDemand =
    ACCESS_DEMANDS && (user?.accessDemands || accessDemands)
  // Const below to delete, created only to test data on prod
  const authorizedAccounts = [
    'raphael.cortes.suez@gmail.com',
    'utilisateur.prod.ece@gmail.com',
  ]
  const ACCESS_SERVICES = authorizedAccounts.includes(user.email)
    ? true
    : Boolean(parseInt(process?.env?.REACT_APP_ACCESS_SERVICES || '1', 10))
  const CREATE_USER_ENABLED = Boolean(
    parseInt(process?.env?.REACT_APP_CREATE_USER_ENABLED || '1', 10)
  )
  const [detailPage, setDetailPage] = useState<
    { path: string; name: string } | undefined
  >(undefined)

  const viewingDetailPage = (page: { path: string; name: string }) => {
    setDetailPage({ ...detailPage, ...page })
  }
  const [showingCreationModal, setShowingCreationModal] = useState<boolean>(
    false
  )

  // GTM Data layers - For all pages
  //---------------------------------------
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'sz_conversion',
      sz_conversion_type: 'Cumul de visites des pages',
      userType: user.isInternal ? 'Interne' : 'Externe',
      userRole: roleNames,
    })
  }, [location])
  //--------------------------------------

  /** ****************
    Quatrics
  ******************* */
  // Loads the Site Intercept code for any defined intercepts or creatives
  useEffect(() => {
    if (window.QSI && window.QSI.API) {
      window.QSI.API.unload()
      // Loads the Site Intercept code for any defined intercepts or creatives
      window.QSI.API.load()
      window.QSI.API.run()
    }
  }, [location])

  const displayingCreationModal = (showingCreationModal: boolean) => {
    setShowingCreationModal(showingCreationModal)
  }

  // collect Toast messages
  useEffect(() => {
    const alreadysent = cookies.get('side_messages_sent')
    async function callToastMessagesApi() {
      try {
        const messages: ToastMessagesEntity[] = await getToastMessages()
        messages.forEach((message) => {
          toast(message.messagetext, {
            id: message.id,
            duration: 20000,
            position: 'top-right',
            // Styling
            className: 'toast-message',
          })
        })
        cookies.set('side_messages_sent', true, {
          path: '/',
          secure: true,
        })
      } catch (error) {
        history.push(ROUTES.TechnicalError)
      }
    }
    if (user.roleNames.length && !alreadysent) callToastMessagesApi()
  }, [user.roleNames])

  useEffect(() => {
    const maxTotalLimit = parseInt(
      `${process.env.REACT_APP_FILTER_MAX_ORGANIZATION}`,
      10
    )
    async function loadInstitutionsPerimeter() {
      const {
        entities: resultEntities,
      } = await searchInstitutionByTextAndOrganizationsIds(
        '',
        [],
        history,
        maxTotalLimit,
        true,
        200,
        true
      )

      if (resultEntities) {
        dispatch({
          type: ActionTypes.SET_INSTITUTION_PERIMETER,
          payload: {
            institutionPerimeter: resultEntities,
          },
        })
      }
    }
    if (
      PATHS_WITHOUT_PERIMETER_FILTER.includes(location.pathname) &&
      institutionPerimeter.allItems === undefined
    ) {
      loadInstitutionsPerimeter()
    }
  }, [location, institutionPerimeter])

  // Check Maintenance - For all pages
  useEffect(() => {
    async function checkMaintenance() {
      const maintenances = await getCheckMaintenance()
      Object.keys(maintenances).forEach((type) => {
        localStorage.setItem(`maintenanceMode${type}`, maintenances[type])
        // Redirect to the maintenance page
        if (maintenances[MAINTENANCE_TYPE.ALL]) {
          history.push(ROUTES.MaintenanceError)
        }
      })
    }
    checkMaintenance()
  }, [location])

  return (
    <>
      <HeaderAuthenticated />
      <SessionAuthenticated showingCreationModal={showingCreationModal} />
      <div
        className={`screen__scroll ${
          ui.perimeterFilterMode === PERIMETER_MODE.INCREASED_MODE
            ? 'screen__scroll--perimeterFilterBig'
            : ''
        }`}
      >
        <div className="authenticated-screen-main-content">
          {/* BreadCrumbs */}
          <BreadCrumbs detailPage={detailPage} />
          <Switch>
            {/* DASHBOARD PAGE */}
            <CustomRoute
              exact
              path={ROUTES.Dashboard}
              condition={[ROLES.ROLE_CLI_ACTIVITIES, ROLES.ROLE_CLI_BILLING]}
            >
              <AuthenticatedScreenInnerContainer>
                <DashboardPage />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.Dashboard2}
              condition={[ROLES.ROLE_CLI_ACTIVITIES, ROLES.ROLE_CLI_BILLING]}
              personnalizedCondition={!ACCESS_NEW_DASHBOARD}
            >
              <AuthenticatedScreenInnerContainer>
                <Dashboard2 />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.CreateUserProfile}
              condition={[
                ROLES.ROLE_CLI_ADMIN,
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
              ]}
              personnalizedCondition={!CREATE_USER_ENABLED}
            >
              <AuthenticatedScreenInnerContainer>
                <UserProfile />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.UpdateUserProfileDetail}
              condition={[
                ROLES.ROLE_CLI_ADMIN,
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_BILLING,
                ROLES.ROLE_INT_ACTIVITIES,
                ROLES.ROLE_INT_SUPPORT_N1,
              ]}
            >
              <AuthenticatedScreenInnerContainer>
                <UserProfile updateMode />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.Billing}
              condition={[ROLES.ROLE_CLI_BILLING, ROLES.ROLE_INT_BILLING]}
            >
              <AuthenticatedScreenInnerContainer>
                <Billing />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.BillingDetail}
              condition={[ROLES.ROLE_CLI_BILLING, ROLES.ROLE_INT_BILLING]}
            >
              <AuthenticatedScreenInnerContainer>
                <BillingDetail />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.UserPerimeterDetail}
              condition={[
                ROLES.ROLE_CLI_ADMIN,
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_BILLING,
                ROLES.ROLE_INT_ACTIVITIES,
              ]}
            >
              <AuthenticatedScreenInnerContainer>
                <UserPerimeter />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.ModifyUserPerimeterDetail}
              condition={[
                ROLES.ROLE_CLI_ADMIN,
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_BILLING,
                ROLES.ROLE_INT_ACTIVITIES,
                ROLES.ROLE_INT_SUPPORT_N1,
              ]}
            >
              <AuthenticatedScreenInnerContainer>
                <ModifyUserPerimeter />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.CreateOrganization}
              condition={[
                ROLES.ROLE_CLI_ADMIN,
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
              ]}
            >
              <AuthenticatedScreenInnerContainer>
                <CreateOrganizationPage />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.ModifyOrganizationDetail}
              condition={[
                ROLES.ROLE_CLI_ADMIN,
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_SUPPORT_N1,
              ]}
            >
              <AuthenticatedScreenInnerContainer>
                <ModifyOrganization />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.AttachmentInstitutionDetail}
              condition={[
                ROLES.ROLE_CLI_ADMIN,
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_BILLING,
                ROLES.ROLE_INT_ACTIVITIES,
              ]}
            >
              <AuthenticatedScreenInnerContainer>
                <AttachmentInstitution />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.UpdateUserAttachmentInstitutionDetail}
              condition={[
                ROLES.ROLE_CLI_ADMIN,
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_BILLING,
                ROLES.ROLE_INT_ACTIVITIES,
                ROLES.ROLE_INT_SUPPORT_N1,
              ]}
            >
              <AuthenticatedScreenInnerContainer>
                <AttachmentInstitution updateMode />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.ProfileOrganization}
              condition={[
                ROLES.ROLE_CLI_BILLING,
                ROLES.ROLE_CLI_ACTIVITIES,
                ROLES.ROLE_CLI_ADMIN,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_BILLING,
                ROLES.ROLE_INT_ACTIVITIES,
              ]}
            >
              <AuthenticatedScreenInnerContainer>
                <Profile />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <Route path={[ROUTES.Profile]}>
              <AuthenticatedScreenInnerContainer>
                <Profile />
              </AuthenticatedScreenInnerContainer>
            </Route>
            <CustomRoute
              exact
              path={[ROUTES.MyServices]}
              condition={[
                ROLES.ROLE_CLI_ADMIN,
                ROLES.ROLE_CLI_BILLING,
                ROLES.ROLE_CLI_ACTIVITIES,
              ]}
              personnalizedCondition={!ACCESS_SERVICES}
            >
              <AuthenticatedScreenInnerContainer>
                <Profile />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.WasteRegister}
              condition={[ROLES.ROLE_CLI_ACTIVITIES, ROLES.ROLE_INT_ACTIVITIES]}
            >
              <WasteRegister />
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.ActivitySynthesis}
              condition={[ROLES.ROLE_CLI_ACTIVITIES, ROLES.ROLE_INT_ACTIVITIES]}
            >
              <AuthenticatedScreenInnerContainer>
                <ActivitySynthesis />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.ActivityAdvancedReports}
              condition={[ROLES.ROLE_CLI_ACTIVITIES, ROLES.ROLE_INT_ACTIVITIES]}
              personnalizedCondition={!ACCESS_SERVICES} // TODO remove conditionafter activation service
            >
              <AuthenticatedScreenInnerContainer>
                <ActivityAdvancedReports />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.MonoAdvancedReport}
              condition={[ROLES.ROLE_CLI_ACTIVITIES, ROLES.ROLE_INT_ACTIVITIES]}
            >
              <AuthenticatedScreenInnerContainer>
                <MonoAdvancedReport />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.ActivityAnalyseEvolution}
              condition={[ROLES.ROLE_CLI_ACTIVITIES, ROLES.ROLE_INT_ACTIVITIES]}
            >
              <AuthenticatedScreenInnerContainer>
                <ActivityAnalyseEvolution />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.BillingSynthesis}
              condition={[ROLES.ROLE_CLI_BILLING, ROLES.ROLE_INT_BILLING]}
            >
              <AuthenticatedScreenInnerContainer>
                <BillingSynthesis />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.BillingAnalyseEvolution}
              condition={[ROLES.ROLE_CLI_BILLING, ROLES.ROLE_INT_BILLING]}
            >
              <AuthenticatedScreenInnerContainer>
                <BillingAnalyseEvolution />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.AddDocument}
              condition={[
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_ACTIVITIES,
                ROLES.ROLE_INT_BILLING,
              ]}
              personnalizedCondition={
                !canManageDocuement(roleNames, user.accessDocs)
              }
            >
              <AuthenticatedScreenInnerContainer>
                <HandleDocument addMode />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.ModifyDocument}
              condition={[
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_ACTIVITIES,
                ROLES.ROLE_INT_BILLING,
              ]}
              personnalizedCondition={
                !canManageDocuement(roleNames, user.accessDocs)
              }
            >
              <AuthenticatedScreenInnerContainer>
                <HandleDocument />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.DocumentsManagement}
              condition={[
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_ACTIVITIES,
                ROLES.ROLE_INT_BILLING,
              ]}
              personnalizedCondition={
                !canManageDocuement(roleNames, user.accessDocs)
              }
            >
              <AuthenticatedScreenInnerContainer>
                <MyDocuments />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.MyDocuments}
              condition={[
                ROLES.ROLE_CLI_BILLING,
                ROLES.ROLE_CLI_ACTIVITIES,
                ROLES.ROLE_INT_ACTIVITIES,
                ROLES.ROLE_INT_BILLING,
              ]}
            >
              <AuthenticatedScreenInnerContainer>
                <MyDocuments />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.AttestationValorisation}
              condition={[
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_INT_BILLING,
                ROLES.ROLE_CLI_BILLING,
                ROLES.ROLE_CLI_ACTIVITIES,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_ACTIVITIES,
              ]}
            >
              <AuthenticatedScreenInnerContainer>
                <AttestationValorisation />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.UserImpersonation}
              condition={[
                ROLES.ROLE_CLI_ADMIN,
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_ACTIVITIES,
                ROLES.ROLE_INT_BILLING,
                ROLES.ROLE_INT_SUPPORT_N1,
              ]}
            >
              <AuthenticatedScreenInnerContainer>
                <UserImpersonation />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.UsersManagement}
              condition={[
                ROLES.ROLE_INT_ACTIVITIES,
                ROLES.ROLE_INT_BILLING,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_CLI_ADMIN,
                ROLES.ROLE_INT_SUPPORT_N1,
              ]}
            >
              <AuthenticatedScreenInnerContainer>
                <UsersManagement />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.OrganisationsManagement}
              condition={[
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_CLI_ADMIN,
                ROLES.ROLE_INT_SUPPORT_N1,
              ]}
            >
              <AuthenticatedScreenInnerContainer>
                <UsersManagement />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.MessagesManagement}
              condition={[ROLES.ROLE_INT_SUPER_ADMIN]}
            >
              <AuthenticatedScreenInnerContainer>
                <MessagesManagement />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.CreateSideMessage}
              condition={[ROLES.ROLE_INT_SUPER_ADMIN]}
            >
              <AuthenticatedScreenInnerContainer>
                <CreateSideMessage />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.ModifySideMessageDetail}
              condition={[ROLES.ROLE_INT_SUPER_ADMIN]}
            >
              <AuthenticatedScreenInnerContainer>
                <CreateSideMessage />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.ExpandPerimeter}
              condition={[ROLES.ROLE_CLI_ADMIN]}
            >
              <AuthenticatedScreenInnerContainer>
                <ExpandPerimeter />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.ExpandPerimeterMultiple}
              condition={[ROLES.ROLE_CLI_ADMIN]}
            >
              <AuthenticatedScreenInnerContainer>
                <ExpandPerimeter />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.SuperAdminCommunications}
              condition={[ROLES.ROLE_INT_SUPER_ADMIN]}
            >
              <AuthenticatedScreenInnerContainer>
                <ListeCommunication />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.SuperAdminCommunicationsCreate}
              condition={[ROLES.ROLE_INT_SUPER_ADMIN]}
            >
              <AuthenticatedScreenInnerContainer>
                <CreateCommunication />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.SuperAdminCommunicationsUpdate}
              condition={[ROLES.ROLE_INT_SUPER_ADMIN]}
            >
              <AuthenticatedScreenInnerContainer>
                <CreateCommunication />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.Notifications}
              condition={[
                ROLES.ROLE_INT_BILLING,
                ROLES.ROLE_INT_ACTIVITIES,
                ROLES.ROLE_INT_ADMIN_CLIENT,
                ROLES.ROLE_INT_ADMIN_INTERNAL,
                ROLES.ROLE_INT_SUPER_ADMIN,
                ROLES.ROLE_CLI_ACTIVITIES,
                ROLES.ROLE_CLI_BILLING,
                ROLES.ROLE_CLI_ADMIN,
                ROLES.ROLE_INT_SUPPORT_N1,
              ]}
            >
              <AuthenticatedScreenInnerContainer>
                <ListeNotification />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            {hasAccessToDemand && (
              <>
                <CustomRoute
                  exact
                  path={ROUTES.MyDemands}
                  condition={[
                    ROLES.ROLE_CLI_ADMIN,
                    ROLES.ROLE_CLI_BILLING,
                    ROLES.ROLE_CLI_ACTIVITIES,
                  ]}
                  partialMaintenance={{ type: MAINTENANCE_TYPE.DEMANDS }}
                >
                  <AuthenticatedScreenInnerContainer>
                    <MyDemands />
                  </AuthenticatedScreenInnerContainer>
                </CustomRoute>
                <CustomRoute
                  exact
                  path={ROUTES.DemandDetail}
                  condition={[
                    ROLES.ROLE_CLI_ADMIN,
                    ROLES.ROLE_CLI_BILLING,
                    ROLES.ROLE_CLI_ACTIVITIES,
                  ]}
                  partialMaintenance={{ type: MAINTENANCE_TYPE.DEMANDS }}
                >
                  <AuthenticatedScreenInnerContainer>
                    <DemandDetail viewingDetailPage={viewingDetailPage} />
                  </AuthenticatedScreenInnerContainer>
                </CustomRoute>
                <CustomRoute
                  exact
                  path={ROUTES.CreateDemand}
                  condition={[
                    ROLES.ROLE_CLI_ADMIN,
                    ROLES.ROLE_CLI_BILLING,
                    ROLES.ROLE_CLI_ACTIVITIES,
                  ]}
                  partialMaintenance={{ type: MAINTENANCE_TYPE.DEMANDS }}
                >
                  <AuthenticatedScreenInnerContainer>
                    <CreateDemand displayingModal={displayingCreationModal} />
                  </AuthenticatedScreenInnerContainer>
                </CustomRoute>
              </>
            )}
            <CustomRoute
              exact
              path={ROUTES.SuperAdminUsersManagement}
              condition={[ROLES.ROLE_INT_SUPER_ADMIN]}
            >
              <AuthenticatedScreenInnerContainer>
                <SuperAdmin />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
            <CustomRoute
              exact
              path={ROUTES.SuperAdminKPI}
              condition={[ROLES.ROLE_INT_SUPER_ADMIN]}
            >
              <AuthenticatedScreenInnerContainer>
                <KPIs />
              </AuthenticatedScreenInnerContainer>
            </CustomRoute>
          </Switch>
        </div>
      </div>
      {/* FOOTER */}
      <Route path={DASHBOARD_ROUTES}>
        <Footer />
      </Route>
      <Toaster>
        {(t) => (
          <ToastBar toast={t}>
            {({ message }) => (
              <>
                {message}
                <span>
                  <button type="button" onClick={() => toast.dismiss(t.id)}>
                    <SvgIcon
                      name="close"
                      className="toast-message__closeIcon"
                    />
                  </button>
                </span>
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </>
  )
}

export default AuthenticatedRoutes
