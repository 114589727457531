/* eslint-disable import/prefer-default-export */
import axios, { CancelTokenSource } from 'axios'

import { isCancel } from '../billing-services'
import NotificationNormalizer from '../../normalizer/Notifications/NotificationNormalizer'
import NotificationECE from '../../utils/interfaces/Notifications/NotificationECE'

let cancelToken: CancelTokenSource

export const getNotifications = async (
  pageIndex: number,
  pageSize: number,
  idContactWdh: string
): Promise<NotificationECE[]> => {
  if (!idContactWdh) return []
  const url = `${process.env.REACT_APP_NH_URL}/Notification`

  const getParams = {
    pageIndex,
    pageSize,
    applicationCode: process.env.REACT_APP_NH_APPLICATION_CODE,
    destinataireCode: idContactWdh,
  }

  try {
    const resp = await axios({
      method: 'POST',
      url,
      data: getParams,
      headers: {
        KeyId: process.env.REACT_APP_NH_API_KEY,
        Authorization: '',
      },
    })
    if (resp?.data) {
      const results = NotificationNormalizer.normalize(resp?.data)
      return results
    }
    return []
  } catch (err) {
    throw new Error(`Erreur lors de l'appel api NH : liste des notifications`)
  }
}

export const updateNotification = async (id: number): Promise<number> => {
  const url = `${process.env.REACT_APP_NH_URL}/Notification/ChangeState?VuApplication=true`

  const params = new URLSearchParams({
    id: String(id),
  })

  cancelToken = axios.CancelToken.source()

  try {
    const resp = await axios({
      method: 'POST',
      url,
      params,
      cancelToken: cancelToken.token,
      headers: {
        KeyId: process.env.REACT_APP_NH_API_KEY,
        Authorization: '',
      },
    })

    return resp.status
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve(err)
    }
    console.error(err)
    throw new Error(`Erreur lors de l'appel api NH : Modifier une notification`)
  }
}

export const deleteNotification = async (id: number): Promise<number> => {
  const url = `${process.env.REACT_APP_NH_URL}/Notification/${id}`

  try {
    const resp = await axios({
      method: 'DELETE',
      url,
      headers: {
        KeyId: process.env.REACT_APP_NH_API_KEY,
        Authorization: '',
      },
    })
    return resp.status
  } catch (err) {
    console.error(err)
    throw new Error(
      `Erreur lors de l'appel api NH : Supprimer une notification`
    )
  }
}
